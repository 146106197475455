import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Navbar() {
    const profileImage = localStorage.getItem("profile");
    const name = localStorage.getItem("name") || "SubAdmin"; // Declare 'name' before using it
    const userType = localStorage.getItem("type");
    const contactPerson = userType === "subadmin" ? name : (localStorage.getItem("contactPerson") || "Admin");
    const email = localStorage.getItem("email") || "admin@gmail.com";
    
    

    let parsedProfileImage = "../4Shifter-icon.png"; // Default image
    if (profileImage) {
        try {
            parsedProfileImage = JSON.parse(profileImage);
        } catch (error) {
            console.error("Error parsing profile image JSON:", error);
        }
    }



    let location = useLocation();
    console.log(location);
    let navigate = useNavigate();

    const handleProfileClick = () => {
        const userType = localStorage.getItem("type"); // Get user type from storage
    
        if (userType === "subadmin") {
            console.log("Subadmin cannot access the profile page");
            return; // Stop execution if the user is a subadmin
        }
    
        console.log("Redirecting to profile page");
        navigate('/profile');
    };
    

    return (
        <>
            <div className='sticky lg:top-0  max-sm:top-10 sm:top-10 md:mt-10 lg:-mt-10 bg-white'>
                <div className="flex justify-between sm:p-5 lg:p-10 lg:pl-2">
                    <div>
                        <h1 className="text-[22px]" style={{ fontFamily: 'Inder' }}>Welcome Back :)</h1>
                        <p className="text-[14px] mt-2" style={{ fontFamily: 'Inder' }}>Manage and forecast your project online.</p>
                    </div>
                    <div onClick={handleProfileClick} className="flex space-x-2 mr-3 max-sm:hidden cursor-pointer">
                        <img
                            src={parsedProfileImage || "../4Shifter-icon.png"}
                            className="h-[40px] w-[40px] rounded-full"
                            alt={"admin profile"}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../4Shifter-icon.png";
                            }}
                        />
                        <div className="text-[14px]">
                            <h1 className="text-blue-700 font-semibold">{contactPerson}</h1>
                            <p>{email}</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Navbar;
