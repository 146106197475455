import React, { useState, useRef } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import HttpsIcon from '@mui/icons-material/Https';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import urlConfig, { url } from '../../urlConfig';

function NewPassword() {
  const [email, setEmail] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [error, setError] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isPasswordStage, setIsPasswordStage] = useState(false);
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const inputRefs = useRef([]);

  const navigate = useNavigate();

  const handleContinue = async () => {
    if (!email) {
      setError('Please enter a valid email address.');
      toast.error('Please enter a valid email address.');
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${url}/send-forgot-otp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (data.status) {
        setIsOtpSent(true);
        toast.success('OTP sent successfully!');
        setError('');
      } else {
        setError(data.message);
        toast.error(data.message);
      }
    } catch (err) {
      setError('Failed to send OTP. Please try again.');
      toast.error('Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async () => {
    const otpValue = otp.join("");
    if (otpValue.length !== 5) {
      setError('Please enter a valid 5-digit OTP.');
      toast.error('Please enter a valid 5-digit OTP.');
      return;
    }
    try {
      const response = await fetch(`${url}/verify-forgot-otp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, OTP: otpValue }),
      });
      const data = await response.json();
      if (data.status) {
        setIsPasswordStage(true);
        toast.success('OTP verified successfully!');
        setError('');
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError('Failed to verify OTP. Please try again.');
      toast.error('Failed to verify OTP. Please try again.');
    }
  };

  const handlePasswordSubmit = async () => {
    if (!password || password !== rePassword) {
      setError('Passwords do not match.');
      toast.error('Passwords do not match.');
      return;
    }
    try {
      const response = await fetch(`${url}/forgotPassword-update`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, newPassword: password }),
      });
      const data = await response.json();
      if (data.status) {
        toast.success('Password updated successfully!');
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setError(data.message);
        toast.error(data.message);
      }
    } catch (err) {
      setError('Failed to update password. Please try again.');
      toast.error('Failed to update password. Please try again.');
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (!/^\d?$/.test(value)) return; // Allow only digits

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];

      if (newOtp[index]) {
        newOtp[index] = ""; // Clear current box
      } else if (index > 0) {
        inputRefs.current[index - 1].focus(); // Move focus back
        newOtp[index - 1] = ""; // Clear previous box
      }

      setOtp(newOtp);
    }
  };

  return (
    <div className="lg:flex max-sm:flex-none">
      <ToastContainer />
      <img
        src="./login.png"
        alt="Login Illustration"
        className="lg:w-[40%] lg:h-[100vh] max-sm:w-[100%] max-sm:h-[35vh] sm:w-[100%] sm:h-[40vh] sm:p-3 lg:p-7 max-sm:p-3"
      />
      <div className="lg:p-10 max-sm:p-4 sm:p-10 items-center lg:pt-28 lg:ml-20">
        <img src="./Watch.png" alt="Logo" className="w-20 h-20 max-sm:hidden" />
        <h1 className="text-[32px] font-bold lg:mt-7 max-sm:-mt-5">
          Forgot Password!
        </h1>
        <p className="pb-5">
          {isPasswordStage
            ? 'Please create a new password'
            : isOtpSent
              ? 'Please enter your OTP'
              : 'Please provide your e-mail for reset password.'}
        </p>

        {!isOtpSent && !isPasswordStage ? (
          <>
            <label className="font-medium max-sm:text-[20px]">Email</label>
            <div className="flex border-2 border-[#D0D5DD] py-2 px-4 lg:w-[370px] sm:w-[60%] rounded-xl shadow-sm lg:mt-2 mb-5">
              <EmailIcon className="text-[#89C8D1] max-sm:mt-2" />
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-gray-400 focus:outline-none max-sm:text-[25px] w-full ml-4"
              />
            </div>
          </>
        ) : isOtpSent && !isPasswordStage ? (
          <div className="flex space-x-2 mb-5">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                ref={(el) => (inputRefs.current[index] = el)}
                onChange={(e) => handleOtpChange(e, index)}
                onKeyDown={(e) => handleOtpKeyDown(e, index)}
                className="border-2 border-[#D0D5DD] w-10 h-12 text-center text-[#344054] focus:outline-none rounded-xl"
              />
            ))}
          </div>
        ) : (
          <>
            <input
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border-2 border-[#D0D5DD] w-full h-12 mb-3 rounded-xl px-4"
            />
            <br />
            <input
              type="password"
              placeholder="Re-Enter Password"
              value={rePassword}
              onChange={(e) => setRePassword(e.target.value)}
              className="border-2 border-[#D0D5DD] w-full mb-10 h-12 rounded-xl px-4 mt-2"
            />
          </>
        )}

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <button
          onClick={isPasswordStage ? handlePasswordSubmit : isOtpSent ? handleOtpSubmit : handleContinue}
          className="bg-[#89C8D1] text-white w-full py-2 rounded-xl"
        >
          {loading
            ? 'Sending OTP...'
            : isPasswordStage
              ? 'Update Password'
              : isOtpSent
                ? 'Verify OTP'
                : 'Send OTP'}
        </button>
      </div>
    </div>
  );
}

export default NewPassword;
