import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Drawer, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';

import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function SideMenu({ msgCount, setMsgCount }) {

    const navigate = useNavigate();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const location = useLocation();

    const token = localStorage.getItem("authToken")
    const socketRef = useRef(null);
    const getUserIdFromToken = (token) => {
        if (!token) {
            console.error("Auth token is missing.");
            return null;
        }

        try {
            const payload = JSON.parse(atob(token.split(".")[1])); // Decode the JWT payload
            const userId = payload?.admin?._id; // Adjust for nested structure
            if (!userId) {
                console.error("User ID (_id) is missing in token payload:", payload);
                return null;
            }
            return userId;
        } catch (err) {
            console.error("Error decoding token:", err);
            return null;
        }
    };

    function handleConnectSocket() {
        if (!token) return;

        const userId = getUserIdFromToken(token);
        if (!userId) {
            console.error("Unable to establish WebSocket: Invalid user ID.");
            return;
        }
        console.log(userId)
        socketRef.current = new WebSocket(`ws://159.65.153.38:5000/?userId=${userId}`);

        socketRef.current.onopen = () => {
            console.log("WebSocket connection established.");
        };

        socketRef.current.onmessage = (event) => {
            const message = JSON.parse(event.data);

            console.log("Received message via WebSocket:", message);
            // message.timestamp = new Date()
            localStorage.setItem("msgCount", msgCount + 1)
            setMsgCount(msgCount => msgCount + 1)
            // Add the message to the state
            // setMessages((prevMessages) => [...prevMessages, message]);
            // setLastMessage(message)
            // // Update the chatData list to reflect the latest message for the correct sender
            // setChatData((prevChatData) =>
            //   prevChatData.map((chat) =>
            //     chat._id === message.sender || chat._id === message.receiver
            //       ? {
            //         ...chat,
            //         lastMessage: message,
            //         unread: [...(chat.unread || []), message]
            //       }

            //       : chat

            //   )
            // );
            // chatData?.map(chat=>{
            //   if(chat._id===message.sender || chat._id===message.receiver){
            //     chat.lastMessage=message
            //   }
            // })
        };


        socketRef.current.onclose = () => {
            console.log("WebSocket disconnected.");
        };

        socketRef.current.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
                console.log("WebSocket connection closed.");
            }
        };
    }

    useEffect(() => {

    }, [token, setMsgCount]);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleLogoutClick = (event) => {
        event.preventDefault();
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleConfirmLogout = () => {

        localStorage.removeItem('authToken');
        localStorage.removeItem('theAdminId');
        localStorage.removeItem('loginStatus');
        localStorage.removeItem('type');
        localStorage.removeItem('SubAdminId');
        setModalOpen(false);


        navigate('/');
    };

    const type = localStorage.getItem('type');

    const drawerContent = (
        <ul className="text-[14px] mt-10 lg:ml-4 max-sm:-ml-6 pl-8 font-medium mb-5 mr-10 md:-ml-3">
            <li className="group">
                <NavLink to="/chats" className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all duration-300 ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <ChatBubbleOutlineOutlinedIcon className="mr-3 " />
                    Chats {msgCount > 0 && (
                        <span className="text-white relative bottom-1 bg-[#43C595] px-1 rounded-full">
                            {msgCount}
                        </span>
                    )}
                </NavLink>
            </li>
            <li className="group ">
                <NavLink to="/employee" onClick={handleConnectSocket} className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <PeopleAltOutlinedIcon className="mr-3 mb-1" />
                    Employees
                </NavLink>
            </li>
            <li className="group ">
                <NavLink to="/shifts" onClick={handleConnectSocket} className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <PeopleAltOutlinedIcon className="mr-3 mb-1" />
                    Shifts
                </NavLink>
            </li>
            <li className="group ">
                <NavLink to="/schedule" onClick={handleConnectSocket} className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <SchemaOutlinedIcon className="mr-3 mb-1" />
                    Groups & Schedule
                </NavLink>
            </li>
            <li className="group ">
                <NavLink to="/specialShift" onClick={handleConnectSocket} className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <SchemaOutlinedIcon className="mr-3 mb-1" />
                    Special Shift
                </NavLink>
            </li>

            <li className="group ">
                <NavLink to="/leave" onClick={handleConnectSocket} className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <SchemaOutlinedIcon className="mr-3 mb-1" />
                    Leave Management
                </NavLink>
            </li>
            <li className="group ">
                <NavLink to="/rewards" onClick={handleConnectSocket} className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <StarsOutlinedIcon className="mr-3 mb-1" />
                    Rewards
                </NavLink>
            </li>

            <li className="group ">
                <NavLink to="/reports" onClick={handleConnectSocket} className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <SummarizeOutlinedIcon className="mr-3 mb-1" />
                    Reports
                </NavLink>
            </li>
            {type !== "subadmin" && (
                <li className="group">
                    <NavLink to="/subadmin" onClick={handleConnectSocket} className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                        <Groups2OutlinedIcon className="mr-3 mb-1" />
                        Sub Admins
                    </NavLink>
                </li>
            )}

            {/* <li className="group ">
                <NavLink to="/profile" className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <SettingsOutlinedIcon className="mr-3 mb-1" />
                    Manage Profile
                </NavLink>
            </li> */}

            {type !== "subadmin" && (
                <li className="group">
                    <NavLink to="/profile" className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                        <SettingsOutlinedIcon className="mr-3 mb-1" />
                        Manage Profile
                    </NavLink>
                </li>
            )}
            <li className="group ">
                <NavLink to="/" onClick={handleLogoutClick} className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <LogoutIcon className="mr-3 mb-1" />
                    Logout
                </NavLink>
            </li>
        </ul>
    );

    return (
        <>
            <div className="w-[20%] bg-gray-50 border-r-2 overflow-y-auto hidden lg:block">
                <div className="flex justify-center pt-10 sticky top-0 bg-gray-50 pb-14">
                    <img src="/4Shifter.png" className="lg:w-[200px] mt-3" />
                </div>
                {drawerContent}
            </div>
            <div className="lg:hidden absolute w-[95vw] bg-white max-sm:ml-3 sm:ml-2">
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                    <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
                    <div className="w-[230px] max-sm:w-[210px] bg-gray-50" role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
                        <div className="flex justify-center pt-6 bg-gray-50 lg:pb-14">
                            <img src="/4Shifter.png" className="w-[200px] max-sm:w-[150px] md:w-[170px] md:-ml-2 max-sm:-ml-4 sm:w-[150px] mt-3" />
                        </div>
                        {drawerContent}
                    </div>
                </Drawer>
            </div>

            {/* Logout Confirmation Modal */}
            <Dialog open={modalOpen} onClose={handleCloseModal}>
                <DialogTitle sx={{ fontSize: "24px" }} className=" text-center font-bold text-[#FD474D]"> Logout</DialogTitle>
                <DialogContent sx={{ fontSize: "22px" }}>
                    Are you sure you want to logout?
                </DialogContent>
                <DialogActions className="lg:mr-5 mb-5">
                    <button onClick={handleConfirmLogout} className="border border-green-500 text-[18px] font-semibold bg-[#FD474D] text-white px-16 py-2 rounded-2xl hover:bg-white hover:text-black ">
                        Yes
                    </button>
                    <button onClick={handleCloseModal} className="border border-green-500 bg-[#FD474D] text-[18px] font-semibold text-white px-16 py-2 rounded-2xl hover:bg-white hover:text-black">
                        No
                    </button>

                </DialogActions>
            </Dialog>
        </>
    );
}

export default SideMenu;
