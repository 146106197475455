import { Button } from "@mui/material";

function PaymentInfo() {
    return (
        <>
            <div className="p-10 max-sm:p-2 text-center max-sm:sticky max-sm:top-0 bg-white max-sm:border-b ">
                <img src="/4Shifter.png" className="w-[200px] max-sm:w-[150px] mt-3" />
                <p className="text-[22px] max-sm:text-[25px] font-regular max-sm:mt-5">Upgrade account to Business</p>
            </div>
            <div className="lg:flex md:flex  lg:pl-10 max-sm:p-3 sm:p-3">
                {/* Organisation Info */}
                <div className="lg:w-[50%] max-sm:w-[100%] sm:w-[100%]">
                    <div className="w-[100%]">
                        <h1 className="font-semibold text-[22px] max-sm:text-[25px]">Organization Info</h1>
                        <p className="text-[16px] max-sm:text-[16px]  font-regular mb-8 max-sm:-mt-1">Please fill in the details of your organization</p>
                        <label className="font-medium ">Organization Name</label><br />
                        <input
                            type="text"

                            className="form-control border-2 rounded-lg w-[90%] max-sm:w-[100%]  max-sm:pr-20  pl-3 py-4 max-sm:py-2  text-[20px] max-sm:text-[22px] font-medium lg:mt-2 mb-6 max-sm:mb-3 border-black"
                        />
                        <br />
                        <label className="font-medium ">Industry</label><br />
                        <input
                            type="text"

                            className="form-control border-2 rounded-lg w-[90%] max-sm:w-[100%]  max-sm:pr-20 pl-3 py-4 max-sm:py-2 text-[20px]  max-sm:text-[22px] font-medium lg:mt-2 mb-6 max-sm:mb-3   border-black"
                        /><br />
                        <label className="font-medium ">Total Number of Employees</label><br />
                        <input
                            type="number"

                            className="form-control border-2 rounded-lg w-[90%] max-sm:w-[100%]   max-sm:pr-20 pl-3 py-4 max-sm:py-2 text-[20px]  max-sm:text-[22px] font-medium lg:mt-2 border-black"
                        /><br />
                        <div className="flex justify-center items-center ">
                            <button className="text-[22px] text-center lg:px-52 max-sm:px-40 sm:px-40 py-1 rounded-lg mt-8 font-bold bg-blue-700 text-white">
                                Save
                            </button>
                        </div>


                    </div>


                </div>
                <div className="border border-gray-300 h-auto max-sm:mt-10 sm:mt-10"></div>
                {/* Billiling Info */}
                <div className=" lg:ml-20 max-sm:ml-0 sm:ml-0 max-sm:p-2">
                    <h1 className="font-semibold text-[22px] ">Billing Information</h1>
                    <p className="text-[16px] font-regular">This is sub description</p>
                    <div className="bg-blue-700 mt-4 text-white rounded-lg p-3 pr-5">
                        <div className="flex text-[22px] font-bold space-x-12">
                            <p >Upgrading to Business</p>
                            <p>5,50 €/Employee</p>
                        </div>
                        <div className="flex text-[20px] font-regular mt-5 lg:pb-4 justify-between">
                            <p>5,50 € * 10 Employees * 12 Months</p>
                            <p>660 €</p>
                        </div>
                    </div>
                    <div className="flex text-[20px] font-regular justify-between p-5  max-sm:pb-0">
                        <p>Discount & Offers (55% Annual)</p>
                        <p>330 €</p>
                    </div>
                    <div className="flex text-[20px] font-regular justify-between p-5 max-sm:pb-0 ">
                        <p>Tax</p>
                        <p>0 €</p>
                    </div>
                    <div className="border border-gray-300 mt-5 w-full"></div>
                    <div className="flex text-[22px] font-bold justify-between p-5 lg:mt-6">
                        <p >Total Payable Amount</p>
                        <p>330 €</p>
                    </div>
                    <div className="flex justify-center items-center ">
                        <button className="text-[22px] text-center lg:px-52 max-sm:px-40 sm:px-40 py-1 rounded-lg  font-bold bg-blue-700 text-white">
                            PayNow
                        </button>
                    </div>
</div>
                </div>
            </>
            );
}

            export default PaymentInfo;