import { useLocation, useNavigate } from "react-router-dom";
import SideMenu from "../../Components/Sidebar/sidebar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { url } from "../../urlConfig";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";

function ViewSpecialShift() {
  const location = useLocation();
  const navigate = useNavigate();
  const [shift, setShift] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchShiftData = async (specialId) => {
    try {
      const response = await fetch(`${url}/single-special-shift/${specialId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("authToken")}`,
        },
      });
      const data = await response.json();
      if (data.status) {
        setShift(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Failed to fetch shift details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state?.shift?._id) {
      fetchShiftData(location.state.shift._id);
    }
  }, [location.state]);

  const givenDate = moment(shift?.date, "YYYY-MM-DD");
  const currentDate = moment();
  const isCompleted = currentDate.isAfter(givenDate);
  const bgColor = isCompleted ? "bg-green-500" : "bg-[#FD474D]";
  const statusText = isCompleted ? "COMPLETED" : "IN PROGRESS";

  function handleRemoveMember(specialId, memberId) {
    const confirm = window.confirm("Are you sure to remove member?");
    if (confirm) {
      fetch(`${url}/remove-member/${memberId}?specialId=${specialId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("authToken")}`,
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status) {
            fetchShiftData(specialId); // Re-fetch data after removing a member
            toast.success(response.message);
          } else {
            toast.error(response.message);
          }
        });
    }
  }

  function handleDelete() {
    const confirm = window.confirm("Are you sure to delete?");
    if (confirm) {
      fetch(`${url}/delete-specialShift/${shift._id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("authToken")}`,
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status) {
            toast.success(response.message);
            navigate("/specialShift");
          } else {
            toast.error(response.message);
          }
        });
    }
  }

  if (loading) {
    return <p className="text-center text-lg">Loading...</p>;
  }

  return (
    <>
      <ToastContainer />
      <div className="flex w-full h-screen">
        <div className="px-3 overflow-scroll scrollbar-hide lg:p-5 lg:mt-5 md:p-5 sm:mt-10">
          <div className="flex justify-between">
            <div>
              <h1 className="text-[22px] max-sm:mt-14 font-semibold">
                View Special Shift
              </h1>
              <p>This is sub description</p>
            </div>
          </div>
          <div className="lg:flex lg:mt-10 max-sm:mt-3">
            <img
              src={shift.image || `${process.env.PUBLIC_URL}/4Shifter-icon.png`}
              className="lg:w-[360px] max-sm:w-[100%] lg:h-[280px] rounded-xl"
            />
            <div className="text-[18px] lg:p-3 lg:ml-10 lg:space-y-5 max-sm:space-y-1 max-sm:mt-3">
              <h1>Title - {shift?.shiftTitle}</h1>
              <h2>
                Date - {shift.startDate} {shift.endDate ? `TO ${shift.endDate}` : ""}
              </h2>
              <div
                className={`${bgColor} rounded-lg text-[16px] text-white text-center py-1 lg:w-[100%]`}
              >
                {statusText}
              </div>
              <p>
                Time - {shift?.start} to {shift?.end}
              </p>
              <p>Reward Coins - {shift?.rewardsCoins}</p>
            </div>
          </div>
          <div>
            <h1 className="text-[18px] mt-10">
              View Accepted Employees ({shift?.members?.length})
            </h1>
            <div>
              {shift?.members?.map((i) => (
                <div key={i._id} className="flex justify-between mt-5 mr-3 lg:w-[50%]">
                  <div className="flex space-x-3">
                    <img
                      src={
                        i?.profileImage || `${process.env.PUBLIC_URL}/4Shifter-icon.png`
                      }
                      className="h-[35px] w-[35px] mt-1 rounded-full"
                    />
                    <div>
                      <h1>{i?.name}</h1>
                      <p className="text-[13px] -mt-1 text-gray-400">
                        Accepted on {moment(i?.acceptedAt).format("YYYY-MM-DD HH:mm")}
                      </p>
                    </div>
                  </div>
                  <button
                    className="text-red-600 text-center border border-red-600 rounded-full w-6 h-6 mt-3 me-3"
                    onClick={() => handleRemoveMember(shift._id, i._id)}
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-10 mb-5">
            <h1 className="lg:text-[22px]">Actions</h1>
            <div className="mt-5">
              <button
                className="bg-[#43C595] rounded-xl py-3 px-16 max-sm:px-10 text-center text-white"
                onClick={() => navigate(`/create-special-shift/${shift._id}`)}
              >
                Edit
              </button>
              <button
                className="bg-[#FD474D] rounded-xl py-3 px-16 max-sm:px-10 text-center text-white ml-5"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewSpecialShift;
