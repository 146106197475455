import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { BrowserRouter as Router, Route, Routes ,useLocation } from "react-router-dom"
import './App.css';
import Employees from "./Pages/Employees";
import ManageProfile from "./Pages/Profile";
import Chats from "./Pages/Chats";
import AllShift from "./Pages/Shifts";
import Shedule from "./Pages/Shedules";
import SpecialShift from "./Pages/SpecialShift";
import AllRewards from "./Pages/Rewards";
import Leaves from "./Pages/Leave";
import AllReports from "./Pages/Reports";
import Subscription from "./Pages/Subscription";
import PaymentInfo from "./Pages/PaymentPage";
import Login from "./Pages/Login";
import CreateSpecialShift from "./Pages/SpecialShift/createSpecialShift";
import ViewSpecialShift from "./Pages/SpecialShift/viewSpecialShift";
import  { LoginContext }  from './LoginContext'
import Protected from "./Protected";
import NewPassword from "./Pages/Login/forgotPassword";
import SideMenu from "./Components/Sidebar/sidebar";
import SubAdmin from "./Pages/Subadmin";
// import SubAdmin from "./Pages/Subadmin";

// import EditSpecialShift from "./Pages/SpecialShift/EditSpecialShift";

function App() {
  const token = localStorage.getItem("authToken")
  // localStorage.setItem("msgCount",0)
  const [singleUser,setSingleUser]=useState({})
  const [msgCount,setMsgCount]=useState(0)
  const socketRef = useRef(null);
  const getUserIdFromToken = (token) => {
    if (!token) {
      console.error("Auth token is missing.");
      return null;
    }

    try {
      const payload = JSON.parse(atob(token.split(".")[1])); // Decode the JWT payload
      const userId = payload?.admin?._id; // Adjust for nested structure
      if (!userId) {
        console.error("User ID (_id) is missing in token payload:", payload);
        return null;
      }
      return userId;
    } catch (err) {
      console.error("Error decoding token:", err);
      return null;
    }
  };

  useEffect(() => {
    if (!token) return;

    const userId = getUserIdFromToken(token);
    if (!userId) {
      console.error("Unable to establish WebSocket: Invalid user ID.");
      return;
    }
   console.log(userId)
    socketRef.current = new WebSocket(`ws://159.65.153.38:5000/?userId=${userId}`);

    socketRef.current.onopen = () => {
      console.log("WebSocket connection established.");
    };

    socketRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);

      console.log("Received message via WebSocket:", message);
      // message.timestamp = new Date()
     
      setMsgCount(msgCount=>msgCount+1)
      localStorage.setItem("msgCount",msgCount+1)
      // Add the message to the state
      // setMessages((prevMessages) => [...prevMessages, message]);
      // setLastMessage(message)
      // // Update the chatData list to reflect the latest message for the correct sender
      // setChatData((prevChatData) =>
      //   prevChatData.map((chat) =>
      //     chat._id === message.sender || chat._id === message.receiver
      //       ? {
      //         ...chat,
      //         lastMessage: message,
      //         unread: [...(chat.unread || []), message]
      //       }

      //       : chat

      //   )
      // );
      // chatData?.map(chat=>{
      //   if(chat._id===message.sender || chat._id===message.receiver){
      //     chat.lastMessage=message
      //   }
      // })
    };


    socketRef.current.onclose = () => {
      console.log("WebSocket disconnected.");
    };

    socketRef.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        console.log("WebSocket connection closed.");
      }
    };
  }, [token,setMsgCount]);

  return (
    <>
    <LoginContext.Provider value={{singleUser,setSingleUser,msgCount,setMsgCount}}>
   
    <Router>
    <MainLayout msgCount={msgCount} setMsgCount={setMsgCount}>
      <Routes>
      <Route path='/' element={ <Login/>}></Route>
     <Route path="/new-password" element={<NewPassword/>}></Route>
      <Route path='/chats' element={<Protected Component={Chats}/>}></Route>
      <Route path='/employee' element={<Protected Component={Employees}/>}></Route>
      <Route path='/shifts' element={<Protected Component={AllShift}/>}></Route>
      <Route path='/create-special-shift/:specialId' element={<Protected Component={CreateSpecialShift}/>}></Route>
      <Route path='/special-shift-detail' element={<Protected Component={ViewSpecialShift}/>}></Route>
      <Route path='/schedule' element={<Protected Component={Shedule}/>}></Route>
      <Route path='/specialShift' element={<Protected Component={SpecialShift}/>}></Route>
      {/* <Route path="/edit-special-shift/:shiftId" element={<Protected Component={EditSpecialShift}/>}></Route> */}
      <Route path='/rewards' element={<Protected Component={AllRewards}/>}></Route>
      <Route path='/leave' element={<Protected Component={Leaves}/>}></Route>
      <Route path='/profile' element={<Protected Component={ManageProfile}/>}></Route>
      <Route path='/reports' element={<Protected Component={AllReports}/>}></Route>
      <Route path='/subadmin' element={<Protected Component={SubAdmin}/>}></Route>
      <Route path='/profile/Subscription' element={<Protected Component={Subscription}/>}></Route>
      <Route path='/profile/Subscription/payment' element={<Protected Component={PaymentInfo}/>}></Route>
       
  
      </Routes>
      </MainLayout>
      </Router>
     
      </LoginContext.Provider>

    </>
  );
}

function MainLayout({ children,msgCount,setMsgCount }) {
  const location = useLocation();
    
  const shouldShowSidebar = () => {
    const pathsWithoutSidebar = ['/', '/new-password', '/testb'];
    return !pathsWithoutSidebar.includes(location.pathname);
  };

  return (
    <div className="app-container flex h-[100vh]">
      {/* Render the SideMenu only for routes that require it */}
      {shouldShowSidebar() && <SideMenu  msgCount={msgCount} setMsgCount={setMsgCount}/>}
      
      {/* Main Content Area */}
      <div className={`main-content ${shouldShowSidebar() ? "lg:w-[80%] max-sm:w-[100%] md:w-full sm:w-full" : "w-full"}`}>
        {children}
      </div>
    </div>
  );
}
export default App;
