import React, { useEffect, useState } from "react";
import SideMenu from "../../Components/Sidebar/sidebar";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomDatePicker from "../../Components/TimePicker/datePicker";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { url } from "../../urlConfig";
import { useNavigate } from "react-router-dom";
import CustomDatePickerReport from "../../Components/TimePicker/datePickerReport";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function AllReports() {
    const [employee, setEmployee] = useState('');
    const [employees, setEmployees] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const adminId = localStorage.getItem('theAdminId');
    console.log("Initial selected employee:", employee);
    const profileImage = localStorage.getItem("profile");
    const name = localStorage.getItem("name") || "SubAdmin"; // Declare 'name' before using it
    const userType = localStorage.getItem("type");
    const contactPerson = userType === "subadmin" ? name : (localStorage.getItem("contactPerson") || "Admin");
    const email = localStorage.getItem("email") || "admin@gmail.com";
    let parsedProfileImage = "../4Shifter-icon.png"; // Default image
    if (profileImage) {
        try {
            parsedProfileImage = JSON.parse(profileImage);
        } catch (error) {
            console.error("Error parsing profile image JSON:", error);
        }
    }


    const navigate = useNavigate();

    const today = new Date();

    const handleChange = (event) => {
        setEmployee(event.target.value);
    };

    const handleProfileClick = () => {
        const userType = localStorage.getItem("type"); // Get user type from storage

        if (userType === "subadmin") {
            console.log("Subadmin cannot access the profile page");
            return; // Stop execution if the user is a subadmin
        }

        console.log("Redirecting to profile page");
        navigate('/profile');
    };


    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await fetch(`${url}/all-employees/${adminId}?adminId2=${adminId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                        "Content-Type": "application/json",
                    },
                });

                const data = await response.json();
                console.log("Full API Response:", data); // Log entire response
                console.log("Employees from API:", data.data); // Log employees data


                if (data.status === true) {
                    setEmployees(data.data || []);
                    console.log("Employee Data:", data.data);
                } else {
                    console.error("Failed to fetch employees:", data.message);
                }
            } catch (error) {
                console.error("Error fetching employees:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchEmployees();
    }, [adminId]);

    const fetchEmployeeReport = async () => {


        try {
            if (startDate === "") {
                toast.error("Please select start date");
            }
            else if (endDate === "") {
                toast.error("Please select end date")
            }
            else if (employee === "") {
                toast.error("Please select employee")
            } else {
                const response = await fetch(`${url}/reports-emp`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        employeeId: employee,
                        start: startDate,
                        end: endDate,
                        adminId,
                    }),
                });

                const data = await response.json();
                if (data.status === true) {
                    setReportData(data.data || []);
                    console.log("Report Data:", data.data);
                } else {
                    console.error("Failed to fetch report:", data.message);
                }
            }

        } catch (error) {
            console.error("Error fetching report:", error);
        } finally {
            setIsLoading(false);
        }
    };


    const exportAsCSV = () => {
        
        const allShiftsData = [
            ["date", "shift", "shiftStartTime & shiftEndTime", "workHours", "coins"],
            ...reportData.map(report => [
                report.date,
                report.shiftType,
                `${report.shiftStartTime} - ${report.shiftEndTime}`,
                report.workHours,
                `${report.coins} Coins`
            ])
        ];

        let csvContent = "All Shifts\n";
        allShiftsData.forEach(row => {
            csvContent += row.join(",") + "\n";
        });

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "all_shifts_data.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="flex w-full h-screen">
            <ToastContainer />
            {/* <SideMenu /> */}
            <div className="lg:w-[100%] lg:p-5 md:p-5 max-sm:p-4 overflow-scroll scrollbar-hide px-2">
                {/* Navbar */}
                <div className="flex justify-between z-50 sticky -top-5 pb-5 max-sm:top-7 bg-white max-sm:mt-10 sm:mt-10 md:mt-5 ">
                    <div className="mt-3">
                        <h1 className="text-[22px] font-semibold">Reporting</h1>
                        <p className="capitalize">Here you can extract Employees data and KPIs</p>
                    </div>
                    <div onClick={handleProfileClick} className="flex space-x-2 mt-3 mr-3 max-sm:hidden cursor-pointer">
                        <img
                            src={parsedProfileImage || "../4Shifter-icon.png"}
                            className="h-[40px] w-[40px] rounded-full"
                            alt={"admin profile"}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../4Shifter-icon.png";
                            }}
                        />
                        <div className="text-[14px]">
                            <h1 className="text-blue-700 font-semibold">{contactPerson}</h1>
                            <p>{email || "not found"}</p>
                        </div>
                    </div>
                </div>

                {/* Select employee details */}
                <div className="mt-10 lg:flex justify-between">
                    <div>
                        <h1 className="font-semibold text-[22px]">Set Time Filter</h1>
                        <div className="lg:flex md:flex max-sm:flex-none max-sm:space-y-5 items-center lg:gap-4 mb-2">
                            <CustomDatePickerReport label="Start Date" setDate={setStartDate} />
                            <span> - </span>
                            <CustomDatePickerReport label="End Date" setDate={setEndDate} />
                            <button className="bg-blue-600 text-white px-5 py-2 rounded-xl" onClick={() => fetchEmployeeReport()}>
                                Submit
                            </button>
                        </div>


                    </div>
                    <div>
                        <h1 className="font-semibold text-[22px] lg:mr-40 md:w-full max-sm:mt-5">Select Employee Here</h1>
                        <Box sx={{ minWidth: 120, marginTop: "8px" }}>
                            <FormControl className="md:w-[65%] lg:w-full max-sm:w-full">
                                <InputLabel id="demo-simple-select-label">All Employees</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={employee}
                                    label="All Employee"
                                    onChange={handleChange}
                                    sx={{
                                        borderRadius: '12px',
                                    }}
                                >
                                    {employees.map((emp) => (
                                        <MenuItem key={emp.id} value={emp._id}>{emp.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>

                {/* Profile area */}
                <div className="mt-5">
                    <h1 className="font-semibold text-[22px] lg:mr-40 capitalize">Selected Employee:  <span className="font-medium text-[20px]"> {reportData?.employeeName|| "______"}</span> </h1>
                    <div className="mt-5 flex gap-8">
                        <img
                            src={reportData?.profileImage || "../4Shifter-icon.png"}
                            className="h-[80px] w-[80px] rounded-full"
                            alt={"admin profile"}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../4Shifter-icon.png";
                            }}
                        />
                        <div>
                            <div className="leading-5 mb-2">
                                <p className="opacity-50">APPROVED / ALLOCATED LEAVES THIS YEAR</p>
                                <p>{reportData?.totalApprovedLeaves} / 20</p>
                            </div>
                            <div className="leading-5">
                                <p className="opacity-50">GROUP</p>
                                <p>{reportData?.groupName}</p>
                            </div>
                        </div>
                    </div>
                </div>



                {/* Total Summary Table */}
                {reportData && (
                    <>
                        <div className="mt-5">
                            <div className="flex justify-between mb-5">
                                <h1 className="font-semibold text-[22px]">Total Summary</h1>
                                <button onClick={exportAsCSV} className="bg-blue-600 text-white px-5 py-2 ">
                                    Export CSV
                                </button>
                            </div>

                            <div className="overflow-x-auto scrollbar-hide">
                                <table className="table-auto w-full text-center text-sm">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="py-3">Days</th>
                                            <th>Sum Of Shifts</th>
                                            <th>Sum Of Leave Request</th>
                                            <th>Work Hours</th>
                                            <th>Sum Of Coin Rewards</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{reportData.totalDays}</td>
                                            <td>{reportData.sumOfShifts}</td>
                                            <td>{reportData.totalApprovedLeaves}</td>
                                            <td>{reportData.workHours}</td>
                                            <td>{reportData.rewardCoins}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="font-medium mb-2">All Shifts</h1>
                            <div className="overflow-x-auto scrollbar-hide">
                                <table className="table-auto w-full text-center text-sm">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="py-3">Date</th>
                                            <th>Shift Type</th>
                                            <th>Shift Start & End</th>
                                            <th>Work Hours</th>
                                            <th>Coin Reward</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportData.AllShifts?.map((shift, index) => (
                                            <tr key={index}>
                                                <td>{shift.date}</td>
                                                <td>{shift.shiftName}</td>
                                                <td>{shift.start} - {shift.end}</td>
                                                <td>{shift.hours}</td>
                                                <td>{shift.rewardCoins} Coins</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )}

            </div>
        </div>
    );
}

export default AllReports;
