import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Navbar from "../../Components/Navbar/navbar";
import SideMenu from "../../Components/Sidebar/sidebar";
import TimePicker from '../../Components/TimePicker';
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { CircularProgress } from "@mui/material";
import { url } from '../../urlConfig';
import TimePicker2 from '../../Components/TimePicker';
import TimePicker3 from '../../Components/TimePicker';

function SubAdmin() {
    const [subAdmin, setSubAdmin] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [editSubAdmin, setEditSubAdmin] = useState("");
    const [load, setLoad] = useState(true);

    const adminId = localStorage.getItem('theAdminId');

    useEffect(() => {
        const fetchSubAdmins = async () => {
            try {
                const response = await fetch(`${url}/get-all-subadmins/${adminId}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('authToken')}`
                    }
                });

                const data = await response.json();
                if (data.status) {
                    // Filter out the main admin and keep only sub-admins
                    const onlySubAdmins = data.data.filter(subAdmin => subAdmin.adminId === adminId);
                    setSubAdmin(onlySubAdmins);
                }
            } catch (error) {
                console.error("Error fetching subAdmins:", error);
                toast.error("Failed to fetch subAdmins. Please try again.");
            } finally {
                setLoad(false);
            }
        };

        fetchSubAdmins();
    }, [adminId]);






    const validateSubAdmin = () => {
        if (!name) {
            toast.error("Please enter SubAdmin name.");
            return false;
        }

        if (!email || !password) {
            toast.error("Please select valid email and password.");
            return false;
        }
        // if (startTime.isAfter(endTime)) {
        //    toast.error("Start time cannot be later than end time.");
        //    return false;
        // }
        return true;
    };


    const handleSaveSubAdmin = async () => {
        if (!validateSubAdmin()) return;

        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                toast.error("Token is missing");
                return;
            }

            const headers = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            };

            const requestBody = {
                adminId,
                name,
                email,
                password,
            };

            const url2 = editSubAdmin ? `${url}/edit-subadmin/${adminId}` : `${url}/add-subadmins`;

            const response = await fetch(url2, {
                method: editSubAdmin ? "PUT" : "POST",
                headers: headers,
                body: JSON.stringify({ ...requestBody, subAdminId: editSubAdmin }),
            });

            const data = await response.json();

            if (response.status === 401) {
                toast.error("You are not authorized to perform this action. Please login again.");
                return;
            }

            if (data.status) {
                toast.success(editSubAdmin ? "SubAdmin updated successfully!" : "SubAdmin created successfully!");
                window.location.reload();
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error("Error during SubAdmin creation:", error);
            toast.error("Failed to save the SubAdmin. Please try again.");
        }


        setName('');


    };





    const handleDeleteSubAdmin = async (subAdminId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this sub-admin?");
        if (!confirmDelete) return;

        try {
            const response = await fetch(`${url}/delete-sub-admin/${subAdminId}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('authToken')}`
                }
            });

            const data = await response.json();

            if (data.status) {
                toast.success("SubAdmin deleted successfully!");
                setSubAdmin(subAdmin.filter(sub => sub._id !== subAdminId)); // Update UI without reload
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error("Error deleting subAdmin:", error);
            toast.error("Failed to delete subAdmin. Please try again.");
        }
    };






    return (
        <div className="flex w-full h-screen ">
            {/* <SideMenu /> */}
            <div className=" md:w-full  px-3 overflow-scroll  scrollbar-hide">
                <Navbar />
                {/* <div className="lg:-ml-0 mt-10 sticky top-28 bg-white max-sm:mt-5 space-x-8 sm:ml-5">
               <input
                  style={{ fontFamily: 'Montserrat' }}
                  className='w-[400px] h-12 rounded-xl bg-[#f5f3f3] outline-none pl-4 mb-5'
                  placeholder='Search here ....'
               />
            </div> */}
                <ToastContainer />

                <div className="lg:flex mt-10 lg:mt-10 md:mt-0 lg:-ml-8">
                    {/* All Shifts */}
                    <div className="p-10 lg:-mt-8  max-sm:p-0 max-sm:mt-12 lg:w-[50%]    ">
                        <h1 className="text-[22px] font-bold">SubAdmins</h1>
                        <p style={{ fontFamily: "inter" }}>Current SubAdmins are Here</p>
                        {load ? (
                            <div className="flex justify-center text-center mt-10 font-bold text-lg">
                                <HourglassEmptyIcon className="animate-spin" /> Loading...
                            </div>
                        ) : subAdmin.length === 0 ? (
                            <p className="text-center mt-10 font-semibold italic">No SubAdmins Created Yet</p>
                        ) : (
                            subAdmin.map((subAdmin, index) => (
                                <div key={subAdmin._id} className="flex mt-6 lg:space-x-0 max-sm:justify-between sm:justify-between">
                                    <p className="w-10 text-center py-3 font-medium bg-gray-100 rounded-xl">{index + 1}.</p>
                                    <p className="lg:w-[75%] text-start max-sm:w-[70%] sm:w-[70%] pl-3 py-3 cursor-pointer font-medium bg-gray-100 rounded-xl capitalize">
                                        {subAdmin.name} &nbsp; ({subAdmin.email})
                                    </p>

                                    <div className="flex space-x-4">
                                        <button onClick={() => handleDeleteSubAdmin(subAdmin._id)} className='btn hover:bg-gray-100 rounded-full p-2'>
                                            <DeleteOutlinedIcon />
                                        </button>
                                    </div>
                                </div>
                            ))
                        )}



                    </div>
                    <div className="border border-gray-300 h-auto mt-2"></div>
                    {/* Create SubAdmin */}
                    <div className="p-10 lg:-mt-10 max-sm:p-0 max-sm:mt-5 max-sm:mb-5 lg:w-[60%] md:w-full">
                        <h1 className="text-[22px] font-semibold">Add New SubAdmin</h1>
                        <p className='capitalize'>Easily add a new Sub Admin from here</p>
                        <div className="mt-5 bg-white space-y-5">
                            {/* Name Input */}
                            <input
                                type="text"
                                placeholder="SubAdmin Name*"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full px-4 py-3 border border-gray-300 rounded-xl outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            {/* Email Input */}
                            <input
                                type="email"
                                placeholder="SubAdmin Email*"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="off"
                                className="w-full px-4 py-3 border border-gray-300 rounded-xl outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            {/* Password Input */}
                            <input
                                type="password"
                                placeholder="SubAdmin Password*"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                 autoComplete="new-password"
                                className="w-full px-4 py-3 border border-gray-300 rounded-xl outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            {/* Save Button */}
                            <button
                                className="font-medium bg-blue-600 py-2 rounded-xl w-full text-white"
                                onClick={handleSaveSubAdmin}
                            >
                                Save SubAdmin
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default SubAdmin;
